import React from 'react'
import classNames from 'classnames'
import Layout from 'layout/layout'
import { graphql } from 'gatsby'
// import style from 'assets/jss/styles/pages/mainPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import MapSection from 'pages-sections/MapSection';

import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import contactUsStyle from "assets/jss/styles/pages/contactUsStyle.js";

const useStyles = makeStyles(contactUsStyle)

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Budget = ({ data }) => {
  const classes = useStyles()
  const [checked, setChecked] = React.useState([]);
  const [isFilled, setIsFilled] = React.useState(false);
  const [obj, setObj] = React.useState({});
  const { phone, phone2, email, address, city, cp } = data.site.siteMetadata;

  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleChange = e => {
    setObj({ ...obj, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (checked[0] !== 1) return;
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...obj,
      }),
    })
      .then(() => {
        setIsFilled(true);
        // typeof window !== "undefined" && window.gtag("event", "conversion", {'send_to': 'AW-1027855681/5qM-COfxysQBEMGqj-oD'});
      })
      .catch(error => alert(error))
  }


  return (
    <Layout
      pageTitle="Pide presupuesto sin compromiso."
      pageDescription="✨Contacta con nosotros, cuéntanos tus requerimientos y te haremos un presupuesto gratis y sin compromiso. Te asesoramos gratis. Confianza, experiencia y profesionalidad."
      headerImage={require('assets/img/bg8.jpg')}
      contactUs={<MapSection />}
      filter="dark"
      content={
        <h1 className={classes.title}>Pídemos un presupuesto gratuito</h1>
      }
    >
      <h2 className={classes.title}>Envíanos un mensaje</h2>

      <GridContainer>
        <GridItem md={6} sm={6}>
          <p>
            Cuéntanos tu proyecto y nos pondremos en contacto contigo lo antes posible.
            <br />
            <br />
          </p>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <div hidden>
              <label>
                Don’t fill this out:{' '}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </div>

            <CustomInput
              labelText="Nombre"
              id="first"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: handleChange,
                name: 'name',
                type: 'text',
                required: true
              }}                            
            />
            <CustomInput
              labelText="Email"
              id="email-address"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: handleChange,
                name: 'email',
                type: 'email',
                required: true
              }}
            />
            <CustomInput
              labelText="Teléfono"
              id="phone"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: handleChange,
                name: 'phone',
                type: 'phone',
                required: true
              }}
            />
            <CustomInput
              labelText="Mensaje"
              id="message"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                multiline: true,
                rows: 5,
                onChange: handleChange,
                name: 'message',
                required: true
              }}
            />
            <div>                
              {isFilled ? 
                <div className={classes.textCenter}>
                  <h2>¡Gracias!</h2>
                  <p>Nos pondremos en contacto contigo lo antes posible</p>
                </div> :
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => handleToggle(1)}
                        checkedIcon={
                          <Check className={classes.checkedIcon} />
                        }
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="No soy un robot"
                  />
                  <Button type="submit" color="primary" className={classes.pullRight}>
                    Enviar mensaje
                  </Button>
                </div>
              }
            </div>
          </form>
        </GridItem>
        <GridItem md={4} sm={4} className={classes.mlAuto}>
          <InfoArea
            className={classes.info}
            title="Encuéntranos en la oficina"
            description={
              <p>
                {data.site.siteMetadata.address}
                <br /> {data.site.siteMetadata.cp}, {data.site.siteMetadata.city}
              </p>
            }
            icon={PinDrop}
            iconColor="primary"
          />
          <InfoArea
            className={classes.info}
            title="Llámanos"
            description={
              <p>
                {data.site.siteMetadata.phone} <br />
                {data.site.siteMetadata.phone2} <br /> Lunes - Viernes,
                9:00-18:00
              </p>
            }
            icon={Phone}
            iconColor="primary"
          />
          <InfoArea
            className={classes.info}
            title="Email"
            description={
              <p>
                {data.site.siteMetadata.email}
              </p>
            }
            icon={EmailIcon}
            iconColor="primary"
          />
          <InfoArea
            className={classes.info}
            title={<a className={classes.link} target="_blank" href={data.site.siteMetadata.facebook} title="Página de Facebook de Suburled">Facebook</a>}
            icon={FacebookIcon}
            iconColor="primary"
          />
        </GridItem>
      </GridContainer>        
    </Layout>
  )
}

export default Budget

export const query = graphql`
  query ContactUspageQuery {
    site {
      id
      siteMetadata {
        address
        city
        cp
        email
        facebook
        phone
        phone2
      }
    }
  }
`;